import React, {useEffect, useRef} from 'react'

const Canvas = props => {
  const {draw, ...rest} = props
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    let frameCount = 0
    let animationFrameId

    const render = () => {
      frameCount++
      draw(context, frameCount)
      animationFrameId = window.requestAnimationFrame(render)
    }
    render()

    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [draw])

  useEffect(() => {
    const canvas = canvasRef.current
    canvas.width = 700;
    canvas.height = 700;
  }, []);

  return <canvas ref={canvasRef} {...rest}/>
}

export default Canvas
