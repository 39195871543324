const Color = require('color');

let loop1 = 0;
let loop2 = 0;
let loop3 = 0;
let pctHorizontalDirection = 1;
let pctVerticalDirection = 1;
let pctHorizontalMove = 0;
let pctVerticalMove = 0;

const draw = (ctx) => {
  const width = ctx.canvas.width
  const height = ctx.canvas.height

  let pctHorizontalPeakDifference = Math.abs(0.5 - Math.abs(0.5 - Math.abs(pctHorizontalMove))) / 30
  if (pctHorizontalPeakDifference < 0.001) {
    pctHorizontalPeakDifference = 0.001;
  }
  pctHorizontalMove += (pctHorizontalPeakDifference * pctHorizontalDirection);
  if (pctHorizontalMove < 0 || pctHorizontalMove > 1) {
    pctHorizontalDirection *= -1;
  }

  let pctVerticalPeakDifference = Math.abs(0.5 - Math.abs(0.5 - Math.abs(pctVerticalMove))) / 30
  if (pctVerticalPeakDifference < 0.001) {
    pctVerticalPeakDifference = 0.001;
  }
  pctVerticalMove += (pctVerticalPeakDifference * pctVerticalDirection);
  if (pctVerticalMove < 0 || pctVerticalMove > 1) {
    pctVerticalDirection *= -1;
  }

  const pctHorizontal = pctHorizontalMove; // (frameCount % width) / width
  const pctVertical = pctVerticalMove; // (frameCount % height) / height

  loop1 += Math.PI / 200;
  loop2 += Math.PI / 150;
  loop3 += Math.PI / 100;

  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
  ctx.fillStyle = '#0000FF'
  ctx.strokeStyle = '#CCCCCC'
  ctx.lineWidth = 1
  ctx.beginPath()
  // const radius = Math.abs(0.5 - pctHorizontal) * width;
  // if (pctHorizontal < 0.5) {
  //   ctx.arc(width * pctHorizontal, height * pctHorizontal, radius, Math.PI, 2 * Math.PI)
  //   ctx.moveTo(width - width * pctHorizontal + radius, height * pctHorizontal)
  //   ctx.arc(width - width * pctHorizontal, height * pctHorizontal, radius, 0, Math.PI)
  // } else {
  //   ctx.arc(width * pctHorizontal, height * pctHorizontal, radius, Math.PI, 2 * Math.PI)
  //   ctx.moveTo(width - width * pctHorizontal + radius, height * pctHorizontal)
  //   ctx.arc(width - width * pctHorizontal, height * pctHorizontal, radius, 0, Math.PI)
  // }
  //ctx.stroke()

  //ctx.beginPath()
  ctx.moveTo(0, 0)
  ctx.lineTo(width, height)
  ctx.lineTo(width, height * pctVertical)
  ctx.moveTo(width, height)
  ctx.lineTo(width * pctHorizontal, height)
  ctx.lineTo(width, height)
  ctx.moveTo(width * pctHorizontal, height)
  ctx.lineTo(width, height * pctVertical)
  // ctx.lineTo(width * pctHorizontal , height  * pctVertical)
  ctx.moveTo(width * pctHorizontal, height * pctVertical)
  ctx.lineTo(width * pctHorizontal, height)

  const moveTo = width * pctHorizontal
  const lineTo = width - ((width * pctHorizontal) / 2)

  ctx.lineTo(lineTo, height * pctVertical)
  ctx.lineTo(0, height)
  ctx.lineTo(width * pctHorizontal, height * pctVertical)

  ctx.stroke()
  ctx.fillStyle = '#0000FF'
  ctx.strokeStyle = '#FF00FF'
  ctx.lineWidth = 1
  ctx.beginPath()

  ctx.moveTo(moveTo, height * pctVertical)
  ctx.lineTo(lineTo - Math.sin(loop1) * 50, height * pctVertical + Math.cos(loop1) * 100)
  ctx.lineTo(width / 2 - Math.sin(loop1) * 50, height / 2 - Math.cos(loop1) * 50)
  const baseline1x = width / 2 + Math.sin(loop1) * 40
  const baseline1y = height / 2 + Math.cos(loop1) * 40
  ctx.lineTo(baseline1x, baseline1y)
  const baseline2x = baseline1x + Math.sin(loop2) * 80
  const baseline2y = baseline1y + Math.cos(loop2) * 80
  ctx.lineTo(baseline2x, baseline2y)
  const baseline3x = baseline2x - Math.sin(loop3) * 80
  const baseline3y = baseline2y + Math.cos(loop3) * 80
  ctx.lineTo(baseline3x, baseline3y)
  const baseline4x = baseline3x - Math.sin(loop1) * 100
  const baseline4y = baseline3y + Math.cos(loop1) * 100
  ctx.lineTo(baseline4x, baseline4y)

  ctx.stroke()
  ctx.fillStyle = '#0000FF'
  ctx.strokeStyle = '#00FFFF'
  ctx.lineWidth = 5
  ctx.beginPath()

  ctx.moveTo(baseline4x, baseline4y)
  const baseline5x = baseline4x - Math.sin(loop1) * 20
  const baseline5y = baseline4y - Math.cos(loop1) * 20
  ctx.lineTo(baseline5x, baseline5y)

  ctx.stroke()
  ctx.fillStyle = '#0000FF'
  ctx.strokeStyle = '#00FFFF'
  ctx.lineWidth = 1
  ctx.beginPath()

  let lastX;
  let lastY;

  for (let pos = 0; pos < Math.PI * 2; pos += Math.PI / 16) {
    pos += Math.sin(loop3) / 32 + Math.cos(loop2) / 64;
    let color = Color({r: Math.sin(loop1) * Math.PI * 50, g: Math.cos(loop2) * Math.PI * 50, b: Math.sin(loop3) * Math.PI * 50})
    console.log(loop1)
    console.log(loop2)
    console.log(loop3)
    ctx.fillStyle = color.hex()
    ctx.strokeStyle = color.hex()
    const baseline6x = baseline5x - Math.sin(pos) * Math.sin(loop2) * Math.cos(loop3) * 300
    const baseline6y = baseline5y - Math.cos(pos) * Math.cos(loop1) * 300
    ctx.moveTo(baseline5x, baseline5y)
    ctx.lineTo(baseline6x, baseline6y)
    for (let pos1 = 0; pos1 < Math.PI * 2; pos1 += Math.PI / 2) {
      const baseline7x = baseline6x - Math.sin(pos1) * 5
      const baseline7y = baseline6x - Math.cos(pos1) * 5
      ctx.moveTo(baseline6x, baseline6y)
      ctx.lineTo(baseline7x, baseline7y)
    }
    lastX = width / 2 + Math.sin(loop1) * 200
    lastY = height / 2 + Math.cos(loop1) * 200
    ctx.lineTo(lastX, lastY)
  }
  ctx.stroke()
  ctx.fillStyle = '#0000FF'
  ctx.strokeStyle = '#00FF00'
  ctx.lineWidth = 2
  ctx.beginPath()
  ctx.moveTo(lastX, lastY)
  ctx.bezierCurveTo(
    0,
    0,
    width / 2 + Math.sin(loop1) * 150,
    height / 2 + Math.cos(loop2) * 150,
    width / 2 + Math.sin(loop2) * 150,
    height / 2 + Math.cos(loop1) * 150,
  )
  ctx.bezierCurveTo(
    width / 2 + Math.sin(loop2) * 200,
    height / 2 + Math.cos(loop1) * 200,
    width / 2 + Math.sin(loop1) * 300,
    height / 2 + Math.cos(loop2) * 300,
    width / 2 + Math.sin(loop1) * 400,
    height / 2 + Math.cos(loop2) * 400,
  )

  ctx.stroke()
}

export default draw;
