import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Canvas from "./Canvas";
import draw from "./draw";
import {BottomNavigation, BottomNavigationAction, Grid} from "@mui/material";

function App() {
  const mdTheme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography: {
      h3: {
        fontSize: '2em'
      }
    }
  });

  return (
    <>
      <CssBaseline/>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{display: 'flex'}}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h3" color="primary">
                Javascript Render
              </Typography>
              <Button color="secondary">Login</Button>
            </Toolbar>
          </AppBar>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={2} container direction={"column"}>
              <Grid item>
                <Button variant={"contained"}>
                  Test 1
                </Button>
              </Grid>
              <Grid item>
                Test 2
              </Grid>
              <Grid item>
                Test 3
              </Grid>
            </Grid>
            <Grid item xs={6} container direction={"column"}>
              <Grid item>
                Test 1
              </Grid>
              <Grid item>
                <Button variant={"contained"}>
                  Test 2
                </Button>
              </Grid>
              <Grid container item>
                <Grid item>
                  <Button variant={"contained"}>
                    T1
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant={"contained"}>
                    T2
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant={"contained"}>
                    T3
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant={"contained"}>
                    T4
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{width: 700, height: 700}}>
          <Canvas draw={draw}/>
        </Box>
        <BottomNavigation showLabels>
          <BottomNavigationAction label={"Test"}/>
        </BottomNavigation>
      </ThemeProvider>
    </>
  );
}

export default App;
